<template>
	<div id="createmeet" v-loading="loading">
		<div class="left">
			<h1 class="title" v-if="this.registerForm.meetId">01-修改会议信息</h1>
			<h1 class="title" v-else>01-创建会议</h1>
			<el-form :model="registerForm" :rules="rules" ref="registerForm" label-position="top">
				<el-form-item label="所属项目">
					<el-select v-model="registerForm.projectId" :remote-method="getProjectList" placeholder="请选择会议所属项目"
						@change="getProjectByIdFun" filterable remote clearable reserve-keyword disabled
						style="width: 100%;">
						<el-option v-for="item in pjtList" :key="item.id"
							:label="`${item.projectName}${item.projectStatus=='TIMEOUT'?'(已结束)':''}`"
							:value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="会议主题" prop="conferenceTheme">
					<el-input v-model="registerForm.conferenceTheme" placeholder="请输入会议主题"></el-input>
				</el-form-item>
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item label="会议类型" prop="meetingFormat">
							<span slot="label">
								会议类型
								<el-tooltip class="item" effect="dark" placement="top-start">
									<i class="el-icon-info" style="cursor: help;"></i>
									<div slot="content">
										医咻线上会：医咻智会线上会议系统。<br><br>
										腾讯线上会：仅使用医咻智会管理系统，使用腾讯会议开会。 <br><br>
										线下会：线下会议执行流程，需要完善【参会地点】与【详细地址】。
										<!-- <br><br> -->
										<!-- 仅签署：仅使用医咻智会进行线上协议签署。<br><br>
										仅会议：仅使用医咻智会线上会议系统，不进行线上协议签署。 --><br><br>
										仅管理：仅使用医咻智会的合规流程（收集会务材料）。
									</div>
								</el-tooltip>
							</span>
							<el-radio-group v-model="registerForm.meetingFormat" @input="reaioInput">
								<el-radio label="ONLINE">医咻线上会</el-radio>
								<el-radio label="TENCENT">腾讯线上会</el-radio>
								<el-radio label="OFFLINE">线下会</el-radio>
								<!-- <el-radio label="ONLYSIGN">仅签署</el-radio>
								<el-radio label="ONLYMEETING">仅会议</el-radio>-->
								<el-radio label="OTHER">仅管理</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="会议时间" prop="timestamp">
							<el-date-picker v-model="registerForm.timestamp" value-format="timestamp" type="datetime"
								style="width: 100%" placeholder="请选择会议开始时间"></el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item label="参会地点" prop="location" v-if="registerForm.meetingFormat=='OFFLINE'">
					<el-cascader v-model="registerForm.location" :options="regionTree" clearable style="width: 100%;"
						:props="{ value:'name', label:'name'}" @change="cascaderChange"></el-cascader>
				</el-form-item>
				<el-form-item label="详细地址" prop="position" v-if="registerForm.meetingFormat=='OFFLINE'">
					<el-input v-model="registerForm.position" placeholder="请输入详细地址">
						<el-button slot="append" icon="el-icon-map-location"
							@click="mapShow = true;address = registerForm.position">使用地图定位</el-button>
					</el-input>
				</el-form-item>
				<div label="参会医生" class="attendingDoctor">
					<div class="label">参会医生</div>
					<div class="option">
						<el-button class="addStanding" type="primary" plain icon="el-icon-circle-plus-outline"
							@click="addStanding = true">新增参会身份</el-button>
						<el-dialog v-if="addStanding" :visible.sync="addStanding" width="500px">
							<div class="title">新增参会身份</div>
							<el-form ref="StandForm" :model="StandForm" :rules="rules" label-width="120px"
								:modal-append-to-body="true" :append-to-body="true" label-position="top">
								<el-form-item label="参会身份名称" prop="roleName">
									<el-input v-model="StandForm.roleName" placeholder="请输入您要新增的参会身份名称"></el-input>
								</el-form-item>
								<el-form-item label="劳务金额" prop="roleMoney">
									<el-input v-model="StandForm.roleMoney" placeholder="请输入该参会身份的劳务金额"></el-input>
								</el-form-item>
								<el-form-item label="在合同中显示的身份名称">
									<el-input v-model="StandForm.roleShow" placeholder="在合同中显示的身份名称"></el-input>
								</el-form-item>
							</el-form>
							<span slot="footer" class="dialog-footer">
								<el-button @click="addStanding = false;resetForm('StandForm')">取 消</el-button>
								<el-button type="primary" @click="addStandingFun('StandForm')">确 定</el-button>
							</span>
						</el-dialog>

						<el-upload class="upload" :action="`${baseUrl}/sign/live/assistant/importMeetingRole`"
							:data="{meetingId:$route.query.mId}" :headers="{token:token}"
							:on-success="importMeetingRoleSuccess" :show-file-list="false">
							<el-button class="addStanding" type="primary" plain
								icon="el-icon-circle-plus-outline">导入参会人员</el-button>
						</el-upload>
						<el-link type="primary">下载模板</el-link>
					</div>
				</div>
				<el-form-item class="doctors" v-for="item in roleList" :key="item.id">
					<template slot="label">
						<span>
							{{item.roleName}}
						</span>
						<b>
							劳务:￥{{item.roleMoney}}
						</b>
					</template>
					<el-button type="text" icon="el-icon-delete" class="delete" @click="deleteFun(item)">删除</el-button>
					<div class="meetingType" @click="showDoctorsFun(`${item.id}`)">
						<p class="docs" v-if="doctorCk[`${item.id}`][0]">
							<span v-for="(item2,k) in doctorCk[`${item.id}`]" :key="k">
								{{item2.fullName}}
								<template v-if="k<doctorCk[`${item.id}`].length-1">,</template>
							</span>
						</p>
						<span class="placeholder" v-else>请选择参会人员 </span>
						<span class="add">添加<i class="el-icon-plus"></i></span>
					</div>
				</el-form-item>
			</el-form>
		</div>
		<div class="center">
			<h1 class="title">02-人员权限</h1>
			<el-form :model="registerPersonnel" :rules="rules" ref="registerPersonnel" label-position="top">
				<!-- v-loading="loading"> -->
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="立项单位" prop="meetingMechanismId">
							<el-select filterable v-model="registerPersonnel.meetingMechanismId" clearable
								placeholder="请选择立项单位" style="width: 100%;" disabled
								@change="getDepartmentList( $event,'meetingMechanismId')">
								<el-option v-for="item in MechanismList" :key="item.id" :label="item.deptName"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="项目官员" prop="officesIds">
							<el-select filterable v-model="registerPersonnel.officesIds" clearable placeholder="请选择项目官员"
								multiple style="width: 100%;" :disabled="!projectOriginator">
								<el-option v-for="item in projectOriginator" :key="item.id" :label="item.fullName"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<!-- <el-col :span="24">
						<el-form-item label="劝募人员" prop="fundraisingIds">
							<el-select filterable v-model="registerPersonnel.fundraisingIds" clearable
								placeholder="请选择劝募人员" multiple style="width: 100%;" :disabled="!projectOriginator">
								<el-option v-for="item in projectOriginator" :key="item.id" :label="item.fullName"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col> -->
					<el-col :span="24">
						<el-form-item label="支持企业" prop="meetingEnterpriseId">
							<el-select filterable v-model="registerPersonnel.meetingEnterpriseId" clearable
								placeholder="请选择支持企业" style="width: 100%;"
								@change="getDepartmentList($event,'meetingEnterpriseId')">
								<el-option v-for="item in enterPriseList" :key="item.id" :label="item.deptName"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="执行机构" prop="meetingActuatorId">
							<el-select filterable v-model="registerPersonnel.meetingActuatorId" clearable
								placeholder="请选择执行机构" style="width: 100%;" disabled
								@change="getDepartmentList($event,'meetingActuatorId')">
								<el-option v-for="item in orgList" :key="item.id" :label="item.deptName"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="会务负责人" prop="meetingActuatorIds">
							<el-select filterable clearable placeholder="请选择助教" multiple style="width: 100%;"
								v-model="registerPersonnel.meetingActuatorIds"
								:disabled="!executive||!registerPersonnel.meetingActuatorId">
								<el-option v-for="item in executive" :key="item.id" :label="item.fullName"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="劳务负责人" prop="laborActuatorIds">
							<el-select filterable clearable placeholder="请选择助教" multiple style="width: 100%;"
								v-model="registerPersonnel.laborActuatorIds"
								:disabled="!executive||!registerPersonnel.meetingActuatorId">
								<el-option v-for="item in executive" :key="item.id" :label="item.fullName"
									:value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<div class="right">
			<h1 class="title">03-物料相关</h1>
			<el-form :model="registerImg" :rules="rules" ref="registerImg" label-position="top">
				<el-form-item label="会议背景图(主KV)" prop="bgImage">
					<el-upload :action="`${baseUrl}/sign/live/doctor/uploadFile`" :show-file-list="false"
						:on-success="handleAvatarSuccess1" :before-upload="beforeAvatarUpload" :file-list="fileList1">
						<el-image :src="registerImg[1].file?registerImg[1].file:registerImg[1].url"
							v-if="registerImg[1]" fit="cover" class="avatar">
							<div slot="error" class="imageError">
								<i class="el-icon-upload"></i>
								<span>点击上传</span>
							</div>
						</el-image>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="会议海报" prop="bill">
					<el-upload :action="`${baseUrl}/sign/live/doctor/uploadFile`" :show-file-list="false"
						:on-success="handleAvatarSuccess2" :before-upload="beforeAvatarUpload" :file-list="fileList2">
						<el-image :src="registerImg[2].file?registerImg[2].file:registerImg[2].url"
							v-if="registerImg[2]" class="avatar">
							<div slot="error" class="imageError">
								<i class="el-icon-upload"></i>
								<span>点击上传</span>
							</div>
						</el-image>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>
			</el-form>
			<el-dialog :visible.sync="dialogVisible">
				<img width="100%" :src="dialogImageUrl" alt="">
			</el-dialog>
		</div>
		<div class="options">
			<el-button type="info" class="bank" :loading="loading" @click="bank()">返回</el-button>
			<el-button type="primary" class="next" :loading="loading" @click="onSubmit()">发布会议</el-button>
			<el-button type="warning" class="next" :loading="loading" @click="onSubmit2()"
				v-if="meetingStatus=='WAIT_CLAIM'&&registerForm.meetingFormat!='ONLINE'">直接结束会议</el-button>
		</div>

		<el-dialog custom-class="doctorList" :visible.sync="persShow" v-loading="loading" :close-on-click-modal="false">
			<h1 class="title">医生列表</h1>
			<div class="searth">
				<el-input @change="searthDoctorFun()" v-model="nameKeyword" prefix-icon="el-icon-search" clearable
					placeholder="搜索医生姓名/手机号/医院"></el-input>
				<el-select v-model="departVal" placeholder="按科室筛选" clearable filterable @change="searthDoctorFun()">
					<el-option v-for="item in department" :key="item.name" :label="item.name" :value="item.name">
					</el-option>
				</el-select>
				<el-select v-model="titVal" placeholder="按职称筛选" clearable filterable @change="searthDoctorFun()">
					<el-option v-for="item in title" :key="item.name" :label="item.name" :value="item.name">
					</el-option>
				</el-select>
				<el-select v-model="orderBy" placeholder="按职称筛选" filterable @change="searthDoctorFun()">
					<el-option label="综合排序" value="综合排序"> </el-option>
					<el-option label="时间排序" value="时间排序"> </el-option>
				</el-select>
				<el-button type="primary" @click="searthDoctorFun()">搜索</el-button>
				<el-button type="primary" @click="creatorDoctor()">新增医生</el-button>
				<el-button type="primary" @click="persShow = false">确认选择</el-button>
			</div>
			<div class="list">
				<div class="item" v-for="item in doctorList" :key="item.id" @click="checkDoctor(item)">
					<img src="../../../assets/imgs/yisheng.svg" alt="">
					<div class="docInfo">
						<div class="name">{{item.fullName?item.fullName:item.phoneNumber}}</div>
						<div class="hospital">{{item.hospital}}</div>
						<div class="tit">
							<span v-if="item.department">
								<i class="el-icon-timer"></i>
								{{item.department}}</span>
							<span v-if="item.title">
								<i class="el-icon-location"></i>
								{{item.title}}
							</span>
						</div>
						<i class="icon el-icon-success" style="color: #2979ff; font-size: 40px;"
							v-if="doctorIds[doctorType]&&doctorIds[doctorType].includes(item.id)"></i>
					</div>
				</div>
				<div class="null" v-if="!doctorList[0]">
					<img src="../../../assets/imgs/EmptyState.png" alt="">
					<p>列表没有匹配项</p>
				</div>
			</div>
			<el-pagination background layout="prev, pager, next" :total="total" :page-size="size"
				:current-page.sync="page" class="pagination" @current-change="getDoctorList"
				:hide-on-single-page="false"></el-pagination>
		</el-dialog>

		<el-dialog v-if="mapShow" :visible.sync="mapShow" width="1200px">
			<div class="mapBox">
				<div class="searthBox">
					<el-input class="inline-input" v-model="address" placeholder="请输入关键词" clearable
						style="width: 100%;">
						<el-button slot="append" icon="el-icon-search" @click="addressCK = address">搜索</el-button>
					</el-input>
					<el-button type="primary" @click="mapShow = false"
						style="width: 200px;margin-left: 10px;">确认</el-button>
				</div>
				</el-input>
				<baidu-map class="map" ak="5n8tAI8EUkYg4WYfBs2TVn60Qpqmxza3" :center="map.center" :zoom="map.zoom"
					@ready="mapInit" @click="getClickInfo">
					<bm-view class="mapView"></bm-view>
					<bm-city-list anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-city-list>
					<bm-local-search class="bmLocalSearch" :keyword="addressCK" :auto-viewport="true"
						:forceLocal="false" @infohtmlset="infohtmlset"></bm-local-search>
				</baidu-map>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		getMeetRoleList,
		doctorList,
		createMeeting,
		updateMeeting,
		getUpdateMeetInfo,
		updateMeetingImage,
		getProjectById,
		getOrgList,
		getMechanismList,
		getEnterPriseList,
		getAssistantUserList,
		projectList,
	} from '@/request/api.js';
	import regionTree from '@/assets/regionTree.json'
	import title from '@/assets/title.json';
	import department from '@/assets/department.json';
	import baseUrl from "@/request/config.js"
	import axios from 'axios';
	export default {
		name: 'createmeet',
		data() {
			return {
				token: JSON.parse(localStorage.getItem('token')).token,
				addStanding: false,
				StandForm: {
					roleName: '',
					roleMoney: ''
				},
				baseUrl: '',
				loading: false,
				registerForm: {
					meetingFormat: 'ONLINE', // 会议类型
					projectId: '', // 所属项目ID
					meetId: '', // 会议id
					projectType: '', // 所属项目
					startTime: '', // 会议开始时间
					timestamp: '', // 会议开始时间戳
					conferenceTheme: '', // 会议主题
					doctors: '', // 选择参会医生
					cover: '', // 直播封面
					placard: '', // 海报
					location: '',
					position: ''
				},
				meetingStatus: '',
				registerPersonnel: {
					meetingMechanismId: '',
					fundraisingIds: [],
					officesIds: [],
					meetingEnterpriseId: '',
					meetingActuatorId: '',
					actuatorIds: [],
					laborActuatorIds: [],
					meetingActuatorIds: []
				},
				projectInfo: '',
				rules: {
					meetingMechanismId: [{
						required: true,
						message: '请选择立项单位',
						trigger: 'change'
					}],
					fundraisingIds: [{
						required: true,
						message: '请选择劝募人员',
						trigger: 'change'
					}],
					officesIds: [{
						required: true,
						message: '请选择项目官员',
						trigger: 'change'
					}],
					meetingEnterpriseId: [{
						required: false,
						message: '请选择支持企业',
						trigger: 'change'
					}],
					meetingActuatorId: [{
						required: true,
						message: '请选择执行机构',
						trigger: 'change'
					}],
					laborActuatorIds: [{
						required: true,
						message: '请选择劳务负责人',
						trigger: 'change'
					}],
					meetingActuatorIds: [{
						required: true,
						message: '请选择会务负责人',
						trigger: 'change'
					}],
					conferenceTheme: [{
						required: true,
						message: '请输入会议主题',
						trigger: 'change'
					}],
					roleName: [{
						required: true,
						message: '请输入身份名称',
						trigger: 'change'
					}],
					roleMoney: [{
						required: true,
						message: '请输入劳务金额',
						trigger: 'change'
					}],
					meetingFormat: [{
						required: true,
						message: '请选择会议类型',
						trigger: 'change'
					}],
					timestamp: [{
						required: true,
						message: '请选择会议开始时间',
						trigger: 'change'
					}],
					doctors: [{
						validator: (rule, value, callback) => {
							let flag = false;
							for (let key in this.doctorIds) {
								if (this.doctorIds[key].length) {
									flag = true;
									break;
								}
							};
							if (flag) {
								callback();
							} else {
								callback(new Error('请至少添加一个参会人员'));
							}
						},
						trigger: 'change'
					}],
					bgImage: [{
						validator: (rule, value, callback) => {
							if (this.registerImg[1]) {
								callback();
							} else {
								callback();
								// callback(new Error('请上传会议背景图(KV)'));
							}
						},
						trigger: 'blur'
					}],
					bill: [{
						validator: (rule, value, callback) => {
							if (this.registerImg[2]) {
								callback();
							} else {
								callback();
								// callback(new Error('请上传会议海报(会议宣传图)'));
							}
						},
						trigger: 'blur'
					}]
				},

				fileList1: [],
				fileList2: [],
				dialogImageUrl: '',
				dialogVisible: false,
				registerImg: {
					1: '',
					2: ''
				},

				persShow: false,
				doctorType: '',
				roleList: [], // 身份列表
				doctorCk: {}, // 选中的医生
				doctorIds: {}, // 选中的医生

				nameKeyword: '',
				hospitalKeywords: '',
				phonenumberKeywords: '',
				page: 1,
				size: 10,
				total: 0,
				doctorList: '',
				checks: [],
				checked: [],

				department: '',
				departVal: '',
				title: '',
				titVal: '',
				orderBy: '综合排序',

				regionTree: '',

				mapShow: false,
				map: {
					center: {
						lat: 39.914894,
						lng: 116.404146
					},
					zoom: 13,
				},
				bMapObj: '',
				markerWinShow: true,
				address: '',
				addressCK: '',
				surroundingPois: [],

				MechanismList: [], // 立项单位列表
				projectOriginator: '',
				orgList: [], // 执行机构列表
				executive: '',
				enterPriseList: [], // 支持企业列表
				supportStaff: '',

				pjtList: [],
			}
		},
		created() {
			this.regionTree = regionTree;
			this.baseUrl = baseUrl;
			this.title = title;
			this.department = department;
			this.registerForm.projectId = this.$route.query.pId;
			this.registerForm.meetId = this.$route.query.mId;
			this.echo();
			this.getRoleListFun();
			this.getDoctorList();
			this.getProjectByIdFun();
			if (this.registerForm.meetId) {
				this.getMeetingDetail();
			}
			this.getOrgListFun();
			this.getProjectList();
		},
		watch: {
			registerForm: {
				handler(nval, oval) {
					let data = {
						meetTit: nval.conferenceTheme,
						timestamp: nval.timestamp,
						registerImg: this.registerImg
					}
					setTimeout(() => {
						localStorage.setItem('cache', JSON.stringify(data));
					}, 300)
				},
				deep: true
			},
			registerImg: {
				handler(nval, oval) {
					let data = {
						meetTit: this.registerForm.conferenceTheme,
						timestamp: this.registerForm.timestamp,
						registerImg: nval
					}
					setTimeout(() => {
						localStorage.setItem('cache', JSON.stringify(data));
					}, 300)
				},
				deep: true
			}
		},
		mounted() {},
		methods: {
			// 上传参会人员成功
			importMeetingRoleSuccess(res, file) {
				if (res.code == 200) {
					// console.log(JSON.parse(JSON.stringify(res.data)))
					this.roleList = res.data.roles;
					this.doctorCk = res.data.users;
					let ids = {};
					for (let i in res.data.users) {
						ids[i] = [];
						for (let j in res.data.users[i]) {
							ids[i].push(res.data.users[i][j].id)
						}
					}
					this.doctorIds = ids;
					this.$forceUpdate();
				} else {
					this.$message.error(res.msg);
					this.$alert(res.msg, '导入失败', {
						confirmButtonText: '确定',
						type: "error",
						center: true,
					});
				}
			},

			// 获取项目列表
			getProjectList(query) {
				let data = {
					current: 1,
					size: 30,
					projectName: query
				}
				// console.log(data)

				projectList(data).then(res => {
					if (res.code == 200) {
						this.pjtList = res.data.records;
					}
				})
			},

			// 查询部门内人员列表
			getDepartmentList(id, section) {
				// console.log(id, section)
				if (id) {
					let data = {}
					if (section == 'meetingMechanismId') data.mechanismId = id; // 立项单位
					if (section == 'meetingEnterpriseId') data.enterpriseId = id; // 支持企业
					if (section == 'meetingActuatorId') data.actuatorId = id; // 执行机构
					getAssistantUserList(data).then(res => {
						if (res.code == 200) {
							// console.log(res.data)
							if (section == 'meetingMechanismId') this.projectOriginator = res.data; // 立项单位
							if (section == 'meetingEnterpriseId') this.supportStaff = res.data; // 支持企业
							if (section == 'meetingActuatorId') this.executive = res.data; // 执行机构
						}
					})
				} else {
					if (section == 'meetingMechanismId') {
						this.registerPersonnel.officesIds = [];
						this.registerPersonnel.fundraisingIds = [];
						this.projectOriginator = ''; // 立项单位
					}
					if (section == 'meetingEnterpriseId') this.supportStaff = ''; // 支持企业
					if (section == 'meetingActuatorId') {
						this.registerPersonnel.meetingActuatorIds = [];
						this.executive = ''; // 执行机构
					}
				}
			},

			getOrgListFun() {
				//获取立项单位列表
				getMechanismList().then(res => {
					if (res.code == 200) {
						this.MechanismList = res.data;
					}
				})

				// 获取支持企业列表
				getEnterPriseList().then(res => {
					if (res.code == 200) {
						this.enterPriseList = res.data;
					}
				})

				// 获取执行机构列表
				getOrgList().then(res => {
					if (res.code == 200) {
						this.orgList = res.data
						// console.log(res.data)
					}
				})
			},

			deleteFun(item) {
				this.roleList.map(item => item.id).map((id, idx) => {
					if (item.id == id) {
						this.roleList.splice(idx, 1);
						this.doctorCk[item.id] = [];
						this.doctorIds[item.id] = [];
					}
				})
			},

			addStandingFun(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.addStanding = false;
						let stand = JSON.parse(JSON.stringify(this.StandForm));
						stand.id = this.getRandomNumber(12);
						this.roleList = [...this.roleList, stand];
						this.doctorCk[stand.id] = [];
						this.doctorIds[stand.id] = [];
						this.StandForm = {};
					} else {
						return false;
					}
				});
			},

			getRandomNumber(digits) {
				var min = Math.pow(10, digits - 1);
				var max = Math.pow(10, digits) - 1;
				return Math.floor(Math.random() * (max - min + 1)) + min;
			},

			resetForm() {
				this.StandForm = {};
			},

			infohtmlset(e) {
				console.log(e)
				this.address = e.title;
				this.registerForm.position = e.title
				this.registerForm.longitude = e.point.lng
				this.registerForm.latitude = e.point.lat
				// this.registerForm.location = [e.province, e.city]
			},

			querySearch(queryString, cb) {
				var restaurants = this.surroundingPois;
				var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
				// 调用 callback 返回建议列表的数据
				cb(results);
			},

			createFilter(queryString) {
				return (restaurant) => {
					return (restaurant.address.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
				};
			},

			addressSelect(item) {
				console.log(item)
			},

			getClickInfo(e) {
				// 创建地理编码实例
				const myGeo = new BMap.Geocoder();
				// 根据坐标逆解析地址
				myGeo.getLocation(new BMap.Point(e.point.lng, e.point.lat), (result) => {
					if (result) {
						this.address = result.address;
						this.surroundingPois = result.surroundingPois;
					}
				});
			},
			mapInit({
				BMap,
				map
			}) {
				this.bMapObj = map;
				// 添加鼠标滚动缩放
				this.bMapObj.enableScrollWheelZoom(false)
				// 添加缩放平移控件
				this.bMapObj.addControl(new BMap.NavigationControl());
				//添加比例尺控件ƒ
				this.bMapObj.addControl(new BMap.ScaleControl());
				//添加地图类型控件
				// this.bMapObj.addControl(new BMap.MapTypeControl());
				// 点击事件获取经纬度
				// this.bMapObj.addEventListener('click', (e) => {
				// 	console.log(e.point.lng, e.point.lat)
				// 	// // 创建中心点坐标
				// 	// this.map.center.lat = e.point.lat;
				// 	// this.map.center.lng = e.point.lng;
				// 	// var point = new BMap.Point(e.point.lng, e.point.lat);
				// 	// // 设置地图的中心点
				// 	// this.bMapObj.setCenter(point);
				// })

			},

			cascaderChange(e) {
				console.log(e)
			},

			getProjectByIdFun() {
				getProjectById({
					id: this.registerForm.projectId
				}).then(res => {
					if (res.code == 200) {
						this.registerPersonnel.meetingMechanismId = res.data.mechanismId * 1;
						this.registerPersonnel.meetingActuatorId = res.data.actuatorId * 1;
						this.projectInfo = res.data;

						if (this.registerPersonnel.meetingActuatorId) this.getDepartmentList(this.registerPersonnel
							.meetingActuatorId, 'meetingActuatorId');
						if (this.registerPersonnel.meetingEnterpriseId) this.getDepartmentList(this
							.registerPersonnel.meetingEnterpriseId, 'meetingEnterpriseId');
						if (this.registerPersonnel.meetingMechanismId) this.getDepartmentList(this
							.registerPersonnel.meetingMechanismId, 'meetingMechanismId');
					}
				})
			},

			reaioInput(e) {
				console.log(e)
			},

			creatorDoctor() {
				this.$router.push({
					name: 'creatdoc',
					query: {
						from: 'creat',
						pId: this.$route.query.pId
					}
				})
			},

			// 缓存回显
			echo() {
				let cache = JSON.parse(localStorage.getItem("cache"));
				if (cache) {
					if (cache.timestamp) this.registerForm.timestamp = cache.timestamp;
					if (cache.meetTit) this.registerForm.conferenceTheme = cache.meetTit;
					if (cache.registerImg) this.registerImg = cache.registerImg;
				}
			},
			getMeetingDetail() {
				let data = {
					meetingId: this.$route.query.mId
				};
				getUpdateMeetInfo(data).then(res => {
					if (res.code == 200) {
						// this.getProjectDetails();
						this.registerForm.projectId = res.data.projectId;
						this.registerForm.projectName = res.data.projectName;
						this.registerForm.timestamp = res.data.meetingStartTime;
						this.registerForm.conferenceTheme = res.data.meetingTitle;
						this.registerForm.meetingFormat = res.data.meetingFormat;
						this.registerForm.location = res.data.location ? res.data.location.map(item => item
							.trim()) : '';
						this.registerForm.position = res.data.position;
						this.registerForm.longitude = res.data.longitude;
						this.registerForm.latitude = res.data.latitude;
						this.meetingStatus = res.data.meetingStatus;
						this.registerImg[1] = {
							url: res.data.bgImage
						};
						this.registerImg[2] = {
							url: res.data.bill
						};
						let ids = JSON.parse(JSON.stringify(res.data.users))
						for (let i in ids) {
							this.doctorCk[i] = ids[i];
							this.doctorIds[i] = ids[i].map(item => item.id)
						}
						this.registerPersonnel.meetingActuatorId = res.data.meetingActuatorId ? res.data
							.meetingActuatorId * 1 : '';
						this.registerPersonnel.meetingEnterpriseId = res.data.meetingEnterpriseId ? res.data
							.meetingEnterpriseId * 1 : '';
						this.registerPersonnel.meetingMechanismId = res.data.meetingMechanismId ? res.data
							.meetingMechanismId * 1 : '';
						this.registerPersonnel.meetingActuatorIds = res.data.meetingActuatorIds;
						this.registerPersonnel.laborActuatorIds = res.data.laborActuatorIds;
						this.registerPersonnel.fundraisingIds = res.data.fundraisingIds;
						this.registerPersonnel.officesIds = res.data.officesIds;

						if (res.data.meetingActuatorId) this.getDepartmentList(res.data.meetingActuatorId,
							'meetingActuatorId');
						if (res.data.meetingEnterpriseId) this.getDepartmentList(res.data.meetingEnterpriseId,
							'meetingEnterpriseId');
						if (res.data.meetingMechanismId) this.getDepartmentList(res.data.meetingMechanismId,
							'meetingMechanismId');
					}
				});
			},

			// 确认并结束会议
			onSubmit2() {
				let submit1 = false;
				let submit2 = false;
				let submit3 = false;
				this.$refs['registerForm'].validate((valid) => {
					if (valid) {
						submit1 = true;
					} else {
						console.log('error submit!!');
						return false;
					}
				});
				this.$refs['registerPersonnel'].validate((valid) => {
					if (valid) {
						submit2 = true;
					} else {
						console.log('error submit!!');
						return false;
					}
				});
				this.$refs['registerImg'].validate((valid) => {
					if (valid) {
						submit3 = true;
					} else {
						console.log('error submit!!');
						return false;
					}
				});
				if (submit1 && submit2 && submit3) {
					this.loading = true;
					this.updateMeetingFun("FINISH")
				} else {
					this.$message.error('请填写完整信息');
				}
			},

			onSubmit() {
				let submit1 = false;
				let submit2 = false;
				let submit3 = false;
				this.$refs['registerForm'].validate((valid) => {
					if (valid) {
						submit1 = true;
					} else {
						console.log('error submit!!');
						return false;
					}
				});
				this.$refs['registerPersonnel'].validate((valid) => {
					if (valid) {
						submit2 = true;
					} else {
						console.log('error submit!!');
						return false;
					}
				});
				this.$refs['registerImg'].validate((valid) => {
					if (valid) {
						submit3 = true;
					} else {
						console.log('error submit!!');
						return false;
					}
				});
				if (submit1 && submit2 && submit3) {
					this.loading = true;
					this.updateMeetingFun()
				} else {
					this.$message.error('请填写完整信息');
				}
			},

			updateMeetingFun(status) {
				let data = {
					users: this.doctorCk,
					projectId: this.registerForm.projectId,
					meetingStartTime: this.registerForm.timestamp,
					meetingTitle: this.registerForm.conferenceTheme,
					meetingFormat: this.registerForm.meetingFormat,
					bgImage: this.registerImg[1].path,
					bill: this.registerImg[2].path,
					roleList: this.roleList,
					fundraisingIds: this.registerPersonnel.fundraisingIds,
					// actuatorIds: this.registerPersonnel.actuatorIds,
					officesIds: this.registerPersonnel.officesIds,
					meetingMechanismId: this.registerPersonnel.meetingMechanismId,
					meetingActuatorId: this.registerPersonnel.meetingActuatorId,
					meetingEnterpriseId: this.registerPersonnel.meetingEnterpriseId,
					meetingActuatorIds: this.registerPersonnel.meetingActuatorIds,
					laborActuatorIds: this.registerPersonnel.laborActuatorIds
				};
				if (this.registerForm.location) {
					data.location = this.registerForm.location;
				}
				if (this.registerForm.position) {
					data.position = this.registerForm.position;
					data.longitude = this.registerForm.longitude;
					data.latitude = this.registerForm.latitude;
				}
				if (status) {
					data.meetingStatus = status;
				};
				// console.log(JSON.parse(JSON.stringify(data)));
				// return false;

				if (this.registerForm.meetId) {
					data.id = this.registerForm.meetId;
					console.log("修改会议")
					this.loading = true;
					updateMeeting(data).then(res => {
						if (res.code == 200) {
							localStorage.removeItem('cache');
							if (data.meetingStatus == "FINISH") {
								this.$router.push({
									name: 'meetdetails',
									query: {
										mId: data.id,
										status: data.meetingStatus,
										now: 4
									}
								})
							} else {
								this.$router.push({
									name: 'confmeet',
									query: {
										mId: res.data
									}
								})
							}
							this.$message({
								message: '修改成功',
								type: 'success'
							});
							this.$notify({
								type: 'success',
								title: '修改成功',
								message: '修改会议信息成功',
								position: 'bottom-right'
							});
						}
					}).catch(err => {
						this.loading = false;
					})
				} else {
					console.log("创建会议")
					createMeeting(data).then(res => {
						if (res.code == 200) {
							this.loading = false;
							localStorage.removeItem('cache');
							this.$router.push({
								name: 'confmeet',
								query: {
									mId: res.data
								}
							})
							this.$message({
								message: '创建成功',
								type: 'success'
							});
							this.$notify({
								type: 'success',
								title: '创建成功',
								message: '会议创建成功',
								position: 'bottom-right'
							});
						}
					}).catch(err => {
						this.loading = false;
					})
				};


			},

			handleAvatarSuccess1(res, file) {
				console.log(res, file);
				this.registerImg['1'] = {
					file: URL.createObjectURL(file.raw),
					path: res,
					url: res
				}
			},

			handleAvatarSuccess2(res, file) {
				this.registerImg['2'] = {
					file: URL.createObjectURL(file.raw),
					path: res,
					url: res
				}
			},

			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},

			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				const isLt20M = file.size / 10204 / 10204 < 20;
				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				if (!isLt20M) {
					this.$message.error('上传头像图片大小不能超过 20MB!');
				}
				return isLt20M;
			},

			// 选中医生
			checkDoctor(par) {
				for (let i in this.roleList) {
					let item = this.roleList[i];
					if (this.doctorType == `${item.id}`) {
						if (this.doctorIds[`${item.id}`].indexOf(par.id) == -1) {
							this.doctorCk[`${item.id}`] = [...this.doctorCk[`${item.id}`], ...[
								par
							]];
							this.doctorIds[`${item.id}`] = [...this.doctorIds[`${item.id}`], ...[
								par.id
							]];
						} else {
							this.doctorCk[`${item.id}`].splice(this.doctorIds[`${item.id}`].indexOf(par.id), 1);
							this.doctorIds[`${item.id}`].splice(this.doctorIds[`${item.id}`].indexOf(par.id), 1);
						}
					}
				}
				this.$forceUpdate();
			},

			// 打开选择医生页面
			showDoctorsFun(item) {
				// console.log(item);
				this.doctorType = item;
				this.persShow = true;
				this.doctorList = [];
				let checks = [];
				for (let i in this.doctorIds) {
					if (i != item) {
						let item = this.doctorIds[i];
						checks = [...checks, ...item];
					}
				};
				this.checks = checks;
				this.checked = this.doctorIds[item];
				this.getDoctorList();
			},

			getRoleListFun() {
				getMeetRoleList({
					meetingId: this.registerForm.meetId
				}).then(res => {
					if (res.code == 200) {
						this.roleList = res.data ? res.data : [];
						if (res.data[0]) {
							this.doctorType = res.data[0].id;
							res.data.map(item => {
								this.doctorCk[`${item.id}`] = [];
								this.doctorIds[`${item.id}`] = [];
							});
						}
					}
				})
			},

			// getProjectDetails() {
			// 	let projectDetails = this.registerForm.projectId;
			// 	this.registerForm.projectType = this.registerForm.projectType;
			// },

			// 搜索操作
			searthDoctorFun() {
				this.page = 1;
				this.getDoctorList();
			},

			// 获取医生列表
			getDoctorList() {
				// this.loading = true;
				let data = {
					current: this.page,
					size: this.size,
					checks: this.checks.join(','),
					checked: this.checked.join(',')
				};
				if (this.nameKeyword) data.fullName = this.nameKeyword;
				if (this.departVal) data.department = this.departVal;
				if (this.titVal) data.title = this.titVal;
				if (this.orderBy) data.orderBy = this.orderBy;
				doctorList(data).then(res => {
					// this.loading = false;
					if (res.code == 200) {
						this.doctorList = res.data.records;
						this.total = res.data.total;
					}
				})
			},

			bank() {
				this.$confirm('此操作将清空已输入内容的缓存, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.go(-1)
					localStorage.removeItem('cache');
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#createmeet {
		display: flex;
		padding: 20px;
		flex-direction: row;
		flex-wrap: wrap;

		.title {
			font-size: 20px;
			margin-bottom: 20px;
		}

		.center {
			::v-deep .el-form {
				.el-form-item__label {
					line-height: 1;
					color: #112950;

					&:before {
						display: none;
					}
				}
			}
		}

		.left {
			flex: 2;
			padding: 20px;
			margin-right: 20px;
			box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);

			::v-deep .el-form {
				width: 100%;
				display: flex;
				flex-direction: column;

				.meetingType {
					height: 40px;
					border-bottom: 1px solid #E5E5E5;
					padding: 0 15px;
					display: flex;
					justify-content: space-between;

					.docs {}


					.placeholder {
						color: #C0C4CC;
						display: block;
						cursor: pointer;
					}

					.add {
						color: #007AFF;
						display: flex;
						flex-direction: row;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						width: 100px;
					}
				}

				.el-form-item {
					// margin: 14px 0;
				}

				.doctors {
					position: relative;

					.el-form-item__label {
						display: flex;
						align-items: center;
						justify-content: flex-start;

						span {
							width: 140px;
						}

						b {
							font-weight: normal;
						}
					}

					.delete {
						position: absolute;
						right: 0;
						top: 0;
						transform: translateY(-150%);
						padding: 0;
						display: none;
					}

					&:hover {
						.delete {
							display: block;
						}
					}

					.el-form-item__content {
						margin-top: 10px;
						background-color: #F6F7F9;
						padding: 4px 0;
						border-radius: 8px;
					}

				}

				.attendingDoctor {
					display: flex;
					align-items: center;
					// justify-content: space-between;
					margin: 30px 0 20px;

					.label {
						font-size: 18px;
					}

					.option {
						margin-left: 20px;
						display: flex;
						align-items: center;

						.addStanding {
							padding: 4px 5px;
							font-size: 12px;
						}

						.el-link {
							font-size: 12px;
							margin-left: 10px;
						}

						.upload {
							margin-left: 10px;
						}
					}
				}

				.el-form-item__label {
					// padding: 0;
					line-height: 1;
					// color: #112950;

					// &:before {
					// 	display: none;
					// }
				}


				// .el-input__inner {
				// 	border: none;
				// 	border-radius: 0;
				// 	background-color: transparent;
				// }

				// .el-input-group__append {
				// 	border: none;
				// 	background-color: transparent;
				// }

				.el-checkbox {
					display: flex;
					align-items: center;
				}

				.meetingType {
					border-bottom: 0;
				}

				.el-radio-group {
					padding: 0 15px;

					.el-radio {
						margin: 2px 10px 2px 0;
					}
				}
			}

		}

		.center {
			flex: 1;
			padding: 20px;
			margin-right: 20px;
			box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
		}

		.right {
			flex: 1;
			padding: 20px;
			max-width: 350px;
			box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);

			::v-deep .el-upload {
				width: 300px;
				height: 200px;
				display: flex;
				align-items: center;
				justify-content: center;

				.el-icon-plus {
					border: 2px dashed #f5f5f5;
					border-radius: 10px;
					width: 300px;
					height: 200px;
				}

				.imageError {
					width: 100%;
					color: #999;
					height: 100%;
					display: flex;
					align-items: center;
					border-radius: 10px;
					border: 2px solid #eee;
					flex-direction: column;
					justify-content: center;

					span {
						line-height: 1;
					}

					i {
						font-size: 30px;
					}
				}
			}

			.el-icon-plus {
				width: 300px;
				height: 200px;
				font-size: 50px;
				padding: 20px;
				border: 1px dashed #eee;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.avatar {
				width: 300px;
				height: 200px;
				object-fit: cover;
				border-radius: 10px;
			}
		}

		.options {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			padding: 40px 0 20px 0;

			// .el-button {
			// 	padding: 12px 40px;
			// }

			// .next {
			// 	background-color: #007AFF;
			// 	color: #fff;
			// }

			// .back {}
		}

		.el-dialog__wrapper {
			overflow: hidden;
		}

		::v-deep .doctorList {
			margin: 0 !important;
			left: 50%;
			top: 50%;
			min-height: 600px;
			max-height: 90vh;
			transform: translate(-50%, -50%);
			overflow: hidden;
			overflow-y: auto;
			width: 1100px;

			// .el-dialog {
			// 	width: 100%;
			// 	height: 100%;
			// 	margin: 0;
			// }

			.el-dialog__header {
				padding: 0;
			}

			.el-dialog__body {
				display: flex;
				height: 100%;
				flex-direction: column;
			}

			.title {
				font-size: 20px;
				margin-bottom: 20px;
			}

			.searth {
				display: flex;
				flex-direction: row;
				margin-bottom: 20px;

				.el-input {
					flex: 3;
					margin-right: 20px;
				}

				.el-select {
					flex: 2;
					margin-right: 20px;
				}

				.el-button {}
			}

			.list {
				width: 100%;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin-bottom: 30px;
				justify-content: flex-start;
				align-items: flex-start;

				.item {
					width: 49%;
					height: 110px;
					display: flex;
					cursor: pointer;
					transition: all .3s;
					padding: 20px;
					margin-bottom: 10px;
					margin-right: 2%;
					border-bottom: 1px solid #E5E5E5;

					&:nth-of-type(2n) {
						margin-right: 0;
					}

					&:hover {
						box-shadow: 0 0 10px rgba(0, 0, 0, .1);
						border-color: rgb(0, 122, 255);
					}

					* {
						cursor: pointer;
					}

					img {
						width: 68px;
						height: 68px;
						padding: 20px;
						border-radius: 10px;
						background-color: rgb(0, 122, 255);
						margin-right: 10px;
					}

					.docInfo {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						align-items: space-between;
						padding: 3px 5px;
						width: 100%;
						position: relative;

						.icon {
							position: absolute;
							top: 50%;
							right: 10px;
							transform: translateY(-50%);
						}

						.name {
							font-size: 18px;
							display: flex;
							flex-direction: row;
						}

						.hospital {
							font-size: 15px;
							display: flex;
							flex-direction: row;
						}

						.tit {
							font-size: 14px;
							display: flex;
							flex-direction: row;

							span {}
						}
					}
				}

				.null {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 100%;
					padding: 100px;

					img {
						width: 200px;
					}

					p {
						font-size: 18px;
						color: #A6A6A6;
						margin-top: 30px;
					}
				}
			}

			.pagination {
				flex: 1;
				display: flex;
				text-align: center;
				align-items: flex-end;
				justify-content: center;
			}

			.mapBox {
				width: 100%;
				height: 600px;
				overflow-y: auto;
				display: flex;
				flex-direction: column;

				.searthBox {
					display: flex;
					margin: 5px 0 10px 0;

					.el-autocomplete {
						flex: 1;
					}
				}

				.map {
					flex: 1;
					display: flex;
					flex-direction: row;

					.mapView {
						flex: 1;
					}

					.bmLocalSearch {
						height: 100%;
						max-width: 260px;

						>div {
							height: 100%;
						}
					}
				}
			}
		}
	}
</style>